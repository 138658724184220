<template>
  <div class="lottery-statistics">
    <div class="header">
      <div class="title-box">
        <p class="title">历史统计</p>
        <intro-default cont="统计每个位置的单双、大小、龙虎，以及冠亚和大小、冠亚和单双出现的次数。"></intro-default>
      </div>
    </div>
    <div>
      <div class="lz_cont">
        <!-- <ul class="switch">
        <li :class="{active: type.value === switchType }" v-for="type in switchTypes" :key="type.value" @click="switchType = type.value">{{ type.label }}</li>
      </ul> -->
        <ul class="switch">
          <li :class="{ active: i === typeIndex }" v-for="(type, i) in types" :key="type.value" @click="typeAction(i)">
            {{
              type.label }}</li>
        </ul>
        <div class="ranks_box" v-if="locationVisible">
          <span>选择查看:</span>
          <ul class="ranks">
            <li :class="{ active: locationIndex === i }" v-for="(title, i) in titles" :key="title"
              @click="locationIndex = i">{{ title }}</li>
          </ul>
        </div>
        <div class="table">
          <el-table :data="data" border style="width: 100%" header-cell-class-name="table-header-cell"
            cell-class-name="table-cell" row-class-name="table-row" size="small" row-key="id" id="table">
            <el-table-column label="时间" align="center" prop="date" width="120"></el-table-column>

            <template v-if="locationVisible">
              <el-table-column :render-header="renderHeader" align="center">
                <el-table-column v-for="(header, i) in tableHeaders" :key="i" :label="header.label" min-width="42"
                  align="center">
                  <template #default="solt">
                    {{ get_value(solt.row.list[locationIndex][header.value]) }}
                  </template>
                </el-table-column>
              </el-table-column>
            </template>

            <template v-else>
              <el-table-column v-for="(title, i) in titles" :key="title" :label="title" align="center">
                <template v-if="is_append(title)">
                  <el-table-column v-for="header in tableHeaders" :key="header.label" :label="header.label"
                    min-width="42" align="center">
                    <template #default="solt">
                      {{ get_value(solt.row.list[i][header.value]) }}
                    </template>
                  </el-table-column>
                </template>
                <template v-else>
                  <el-table-column v-for="header in types[typeIndex]['append_props']['props']" :key="header.label"
                    :label="header.label" min-width="42" align="center">
                    <template #default="solt">
                      {{ get_value(solt.row.list[i][header.value]) }}
                    </template>
                  </el-table-column>
                </template>
              </el-table-column>
            </template>

          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading } from 'element-ui'
import lotteryCodeHistoryStatisticsType from '@/src/config/lottery-code-history-statistics-type'

export default {
  name: 'LotteryStatistics',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      data: [],
      loading: null,
      typeIndex: 0,
      locationIndex: 0
    }
  },
  computed: {
    types() {
      return lotteryCodeHistoryStatisticsType(this.code)
    },
    tableHeaders() {
      const i = this.typeIndex
      return this.types[i]?.props || []
    },
    selectType() {
      return this.types[this.typeIndex]
    },
    locationVisible() {
      return this.selectType.location
    },
    titles() {
      const [row] = this.data
      if (!row) return []
      const { titles } = row
      if (!titles) return []
      return titles
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.typeIndex = 0
      this.requestGetHistory()
    }
  },
  mounted() {
    this.requestGetHistory()
  },
  methods: {
    get_value(value) {
      if (typeof value === 'object') {
        if (this.code === 'dongganshengxiao') {
          if (this.typeIndex === 3) {
            return value?.zodiac_ratio || 0
          }
        }
        if (this.code === 'dongganpuke') {
          if (this.typeIndex === 2) {
            return value?.value || 0
          }
        }
        if (this.code === 'donggansanbao' || this.code === 'speedy3' || this.code === 'sgquick3') {
          if (this.typeIndex === 3 || this.typeIndex === 4) {
            return value?.sum || 0
          }
        }
        if (this.code === 'donggan61' || this.code === 'hk6' || this.code === 'amlhc' || this.code === 'twlhc' || this.code === 'happy61') {
          if (this.typeIndex === 3) {
            return value?.laber_value || 0
          }
        }
      }
      return value
    },
    renderHeader(h, { column }) {
      return h('span', this.titles[this.locationIndex])
    },
    is_append(title) {
      if (typeof this.types[this.typeIndex]['append_props'] !== 'object') {
        return true
      } else {
        if (this.types[this.typeIndex]['append_props']['type'] !== title) {
          return true
        } else {
          return false
        }
      }
    },
    refresh() {
      this.requestGetHistory()
    },
    typeAction(index) {
      this.typeIndex = index
      this.locationIndex = 0
      this.requestGetHistory()
    },
    async requestGetHistory() {
      this.data = []
      this.showTableLoading()
      await this.$api.historyStatistics(this.code, this.selectType.value).then((res) => {
        this.data = res.data
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';

.lottery-statistics {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }
  }

  >.info {
    margin-top: 25px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);

    span {
      font-weight: bold;
    }
  }

  .switch {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-bottom: 26px;

    >li {
      margin-right: 10px;
      cursor: pointer;
      list-style: none;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #222;
      height: 26px;
      border-radius: 50px;

      &.active {
        color: #FA3E3E;
        background-color: #FEEBEB;
      }
    }
  }

  .ranks_box {
    display: flex;
    align-items: center;

    >span {
      margin-right: 16px;
      margin-bottom: 26px;
      padding-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .ranks {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 26px 0;
    margin-top: 0;

    >li {
      cursor: pointer;
      padding: 0 8px;
      min-width: 70px;
      height: 24px;
      border-radius: 50px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      color: #939395;

      &.active {
        border: 1px solid #FA3E3E;
        color: #FA3E3E;
      }

      &+li {
        margin-left: 10px;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }
}
</style>

<style>
.lottery-statistics .table {
  margin-top: 20px;
}

.lottery-statistics .table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.lottery-statistics .table .table-header-cell>.cell {
  padding: 0;
}

.lottery-statistics .table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 33px;
}

.lottery-statistics .table .table-cell>.cell {
  padding: 0;
}

.lottery-statistics .table .table-row:hover>td,
.lottery-statistics .table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
