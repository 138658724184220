import lotteryCodeLength from '@/src/config/lottery-code-length'

const prop = {
  big: {
    label: '大',
    value: 'big'
  },
  small: {
    label: '小',
    value: 'small'
  },
  single: {
    label: '单',
    value: 'single'
  },
  double: {
    label: '双',
    value: 'double'
  },
  sbig: {
    label: '尾大',
    value: 'big'
  },
  ssmall: {
    label: '尾小',
    value: 'small'
  },
  ssingle: {
    label: '合单',
    value: 'single'
  },
  sdouble: {
    label: '合双',
    value: 'double'
  },
  loong: {
    label: '龙',
    value: 'loong'
  },
  tiger: {
    label: '虎',
    value: 'tiger'
  },
  eq: {
    label: '和',
    value: 'eq'
  },
  yin: {
    label: '阴',
    value: 'yin'
  },
  yang: {
    label: '阳',
    value: 'yang'
  },
  poultry: {
    label: '禽',
    value: 'poultry'
  },
  beast: {
    label: '兽',
    value: 'beast'
  },
  before: {
    label: '前',
    value: 'before'
  },
  after: {
    label: '后',
    value: 'after'
  },
  jin: {
    label: '金',
    value: 'jin'
  },
  mu: {
    label: '木',
    value: 'mu'
  },
  shui: {
    label: '水',
    value: 'shui'
  },
  huo: {
    label: '火',
    value: 'huo'
  },
  tu: {
    label: '土',
    value: 'tu'
  },
  kan: {
    label: '坎',
    value: 'kan'
  },
  kun: {
    label: '坤',
    value: 'kun'
  },
  zhen: {
    label: '震',
    value: 'zhen'
  },
  xun: {
    label: '巽',
    value: 'xun'
  },
  qian: {
    label: '亁',
    value: 'qian'
  },
  dui: {
    label: '兑',
    value: 'dui'
  },
  gen: {
    label: '艮',
    value: 'gen'
  },
  li: {
    label: '离',
    value: 'li'
  },
  hongtao: {
    label: '红桃',
    value: 'hongtao'
  },
  heitao: {
    label: '黑桃',
    value: 'heitao'
  },
  meihua: {
    label: '梅花',
    value: 'meihua'
  },
  fangkuai: {
    label: '方块',
    value: 'fangkuai'
  },
  zodiac: {
    label: '生肖',
    value: 'zodiac'
  },
  zodiacRatio: {
    label: '出肖率',
    value: 'zodiac_ratio'
  },
  red: {
    label: '红',
    value: 'red'
  },
  blue: {
    label: '蓝',
    value: 'blue'
  },
  green: {
    label: '绿',
    value: 'green'
  },
  yellow: {
    label: '绿',
    value: 'yellow'
  },
  big_small_sum: {
    label: '和',
    value: 'big_small_sum'
  },
  single_double_sum: {
    label: '和',
    value: 'single_double_sum'
  },
  number: {
    label: '次数',
    value: 'number'
  },
  time: {
    label: '个数',
    value: 'time'
  }
}

const get_number_array = (start, end, dw) => {
  const arr = []
  const name = dw || ''
  for (let i = start; i <= end; i++) {
    arr.push({
      label: i + name,
      value: i + ''
    })
  }
  return arr
}

const get_zodiac = () => {
  const arr = ['鼠', '牛',	'虎',	'兔',	'龙',	'蛇',	'马',	'羊',	'猴',	'鸡',	'狗',	'猪']
  let r_arr = []
  for (let i in arr) {
    r_arr.push({
      label: arr[i],
      value: i + ''
    })
  }
  return r_arr
}

const get_pai = () => {
  const arr = ['A',	'2', '3',	'4',	'5',	'6',	'7',	'8',	'9',	'10',	'J', 'Q', 'K']
  let r_arr = []
  for (let i in arr) {
    r_arr.push({
      label: arr[i],
      value: i + ''
    })
  }
  return r_arr
}

const get_ertonghao = () => {
  const arr = ['11',	'22', '33',	'44',	'55',	'66']
  let r_arr = []
  for (let i in arr) {
    r_arr.push({
      label: arr[i],
      value: i + ''
    })
  }
  return r_arr
}

const get_erbuhao = () => {
  const arr = ['12',	'13', '14',	'15',	'16',	'23',	'24', '25',	'26',	'34',	'35',	'36', '45',	'46',	'56']
  let r_arr = []
  for (let i in arr) {
    r_arr.push({
      label: arr[i],
      value: i + ''
    })
  }
  return r_arr
}

const get_santonghao = () => {
  const arr = ['111',	'222', '333',	'444',	'555',	'666', '全骰']
  let r_arr = []
  for (let i in arr) {
    r_arr.push({
      label: arr[i],
      value: i + ''
    })
  }
  return r_arr
}

const get_sanbuhao = () => {
  const arr = ['123',	'124', '125',	'126',	'134',	'135',	'136', '145',	'146',	'156',	'234',	'235', '236',	'245',	'246',	'256',	'345',	'346',	'356',	'456']
  let r_arr = []
  for (let i in arr) {
    r_arr.push({
      label: arr[i],
      value: i + ''
    })
  }
  return r_arr
}

const statistics_config = {
  0: [{
    label: '1～10名双面',
    value: 'big_small_single_double',
    location: false,
    props: [prop.big, prop.small, prop.single, prop.double]
  }, {
    label: '车位和双面',
    value: 'sum_big_small_single_double',
    location: false,
    props: [prop.big, prop.small, prop.single, prop.double]
  }, {
    label: '龙虎',
    value: 'loong_tiger',
    location: false,
    props: [prop.loong, prop.tiger]
  }, {
    label: '出球率',
    value: 'appear',
    location: true,
    props: get_number_array(1, 10)
  }, {
    label: '车位和值',
    value: 'sum',
    location: true,
    props: get_number_array(3, 19, '分')
  }],
  8: [{
    label: '1～8球大小单双',
    value: 'big_small_single_double',
    location: false,
    props: [prop.big, prop.small, prop.single, prop.double]
  }, {
    label: '1～8球尾大小合单双',
    value: 'tail_join',
    location: false,
    props: [prop.sbig, prop.ssmall, prop.ssingle, prop.sdouble]
  }, {
    label: '龙虎/总和',
    value: 'sum_loong_tiger',
    location: false,
    props: [prop.loong, prop.tiger],
    append_props: {
      type: '总和',
      props: [prop.big, prop.small, prop.eq, prop.single, prop.double]
    }
  }, {
    label: '出球率',
    value: 'appear',
    location: true,
    props: get_number_array(1, 20)
  }],
  7: [{
    label: '1～7球大小单双',
    value: 'big_small_single_double',
    location: false,
    props: [prop.big, prop.small, prop.big_small_sum, prop.single, prop.double, prop.single_double_sum]
  }, {
    label: '1～7球尾大小合单双',
    value: 'tail_big_small_single_double',
    location: false,
    props: [prop.sbig, prop.ssmall, prop.big_small_sum, prop.ssingle, prop.sdouble, prop.single_double_sum]
  }, {
    label: '总分/波色',
    value: 'bose',
    location: false,
    props: [prop.red, prop.blue, prop.green],
    append_props: {
      type: '总分',
      props: [prop.big, prop.small, prop.big_small_sum, prop.single, prop.double, prop.single_double_sum]
    }
  }, {
    label: '生肖',
    value: 'zodiac',
    location: true,
    props: get_zodiac()
  }, {
    label: '尾数',
    value: 'tail',
    location: true,
    props: get_number_array(0, 9, '尾')
  }, {
    label: '出球率',
    value: 'number',
    location: true,
    props: get_number_array(1, 49)
  }],
  6: [{
    label: '双面',
    value: 'yin_yang_poultry_beast_before_after',
    location: false,
    props: [prop.yin, prop.yang, prop.poultry, prop.beast, prop.before, prop.after]
  }, {
    label: ' 五行',
    value: 'wuxing',
    location: false,
    props: [prop.jin, prop.mu, prop.shui, prop.huo, prop.tu]
  }, {
    label: '八卦',
    value: 'bagua',
    location: false,
    props: [prop.kan, prop.kun, prop.zhen, prop.xun, prop.qian, prop.dui, prop.gen, prop.li]
  }, {
    label: '出肖率',
    value: 'number',
    location: true,
    props: get_zodiac()
  }],
  5: [{
    label: '双面',
    value: 'big_small_single_double',
    location: false,
    props: [prop.big, prop.small, prop.single, prop.double],
    append_props: {
      type: '龙虎',
      props: [prop.loong, prop.tiger, prop.eq]
    }
  }, {
    label: '出球率',
    value: 'appear',
    location: true,
    props: get_number_array(0, 9)
  }],
  4: [{
    label: '双面',
    value: 'big_small_single_double',
    location: false,
    props: [prop.big, prop.small, prop.single, prop.double]
  }, {
    label: '花色',
    value: 'color',
    location: false,
    props: [prop.hongtao, prop.heitao, prop.meihua, prop.fangkuai]
  }, {
    label: '出牌率',
    value: 'number',
    location: true,
    props: get_pai()
  }],
  3.1: [{
    label: '双面',
    value: 'big_small_single_double',
    location: false,
    props: [prop.big, prop.small, prop.single, prop.double]
  }, {
    label: '色波',
    value: 'color',
    location: false,
    props: [prop.yellow, prop.green, prop.blue, prop.red]
  }, {
    label: '出球率',
    value: 'appear',
    location: false,
    props: get_number_array(0, 9)
  }, {
    label: '总和和值',
    value: 'sum',
    location: false,
    props: get_number_array(0, 27, '分')
  }],
  3.2: [{
    label: '双面',
    value: 'sum',
    location: false,
    props: [prop.big, prop.small, prop.single, prop.double]
  }, {
    label: '点数',
    value: 'number',
    location: false,
    props: get_number_array(3, 18, '分')
  }, {
    label: '三军',
    value: 'there_army',
    location: false,
    props: [prop.number, prop.time]
  }, {
    label: '二骰类',
    value: 'two',
    location: false,
    props: get_ertonghao(),
    append_props: {
      type: '二不同',
      props: get_erbuhao()
    }
  }, {
    label: '三骰类',
    value: 'three',
    location: false,
    props: get_santonghao(),
    append_props: {
      type: '三不同',
      props: get_sanbuhao()
    }
  }]
}

export default function lotteryCodeHistoryStatisticsType (code) {
  const length = lotteryCodeLength(code)
  switch (length) {
    case 8:
      return statistics_config[8]
    case 7:
      return statistics_config[7]
    case 6:
      return statistics_config[6]
    case 5:
      return statistics_config[5]
    case 4:
      return statistics_config[4]
    case 3:
      if (code === 'pcdd') {
        return statistics_config[3.1]
      } else {
        return statistics_config[3.2]
      }
    default:
      return statistics_config[0]
  }
}
